import './Form.css';
import FormSignup from './FormSignup';
import sigImg from "../assets/logo-white.png";
const Form = () => {
  return (
    <>
      <div className='form-container'>
        <span className='close-btn'>×</span>
        <div className='form-content-left'>
          <img className='form-img' src={sigImg} alt='sigup' />
        </div>
        <FormSignup/>
      </div>
    </>
  );
};

export default Form;
