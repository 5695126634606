import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../routes/service.css";
import SearchBar from "./SearchBar";
import "./service.css"
function Service(){
  return (
    <>
    <div className="na">
      <Navbar />
    </div>
    <div className="dat">
    <SearchBar/>
    </div>
      <div className="ft4">
      <Footer/>
      </div>

      </>
  );
}

export default Service;