import React, { useState } from 'react';
import './Form.css';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import 'firebase/database';

const FormSignup = () => {
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    password: "",
    password2:"",
  });

  const [errors, setErrors] = useState({});
  const postUserData = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!userData.username.trim()) {
      newErrors.username = "Username is required";
    }

    if (!userData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!isValidEmail(userData.email)) {
      newErrors.email = "Invalid email address";
    }

    if (!userData.password.trim()) {
      newErrors.password = "Password is required";
    }

    if (!userData.password2.trim()) {
      newErrors.password2 = "Password is required";
    }
    if(userData.password.length<8)
    {
      newErrors.password2 = "Password length should be greater than 8";
    }
    if(userData.password!==userData.password2)
    {
      newErrors.password2="Password is not a match"
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const submitData = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const { username, email, password,password2 } = userData;
      const res = fetch('https://qfinder-8cfa2-default-rtdb.firebaseio.com/userDataRecords.json',{
    method:"POST",
    headers:{
      "Content-Type" : "appplication/json",
    },
    body: JSON.stringify({
      username,
      email,
      password,
      password2,
    }),
  }
      );
      if(res)
      {
        alert("Data stored");
      }
      else{
        alert("plz fill the data")
      }
  };
};

  return (
    <>
      <Navbar />
      <div className='form-content-right'>
        <form className='form' method='POST' noValidate id='form'>
          <h1>
            Get started with us today! Create your account by filling out the
            information below.
          </h1>
          <div className='form-inputs'>
            <label className='form-label'>Username</label>
            <input
              className='form-input'
              type='text'
              name='username'
              placeholder='Enter your username'
              id='name'
              value={userData.username}
              onChange={postUserData}
            />
            {errors.username && <p>{errors.username}</p>}
          </div>
          <div className='form-inputs'>
            <label className='form-label'>Email</label>
            <input
              className='form-input'
              type='email'
              name='email'
              id='email'
              placeholder='Enter your email'
              value={userData.email}
              onChange={postUserData}
            />
            {errors.email && <p>{errors.email}</p>}
          </div>
          <div className='form-inputs'>
            <label className='form-label'>Password</label>
            <input
              className='form-input'
              type='password'
              name='password'
              id='password'
              placeholder='Enter your password'
              value={userData.password}
              onChange={postUserData}
            />
            {errors.password && <p>{errors.password}</p>}
          </div>
          <div className='form-inputs'>
            <label className='form-label'>Confirm Password</label>
            <input
              className='form-input'
              type='password'
              name='password2'
              placeholder='Confirm your password'
              value={userData.password2}
              onChange={postUserData}
            />
            {errors.password2 && <p>{errors.password2}</p>}
          </div>
          <button className='form-input-btn' type='submit' onClick={submitData}>
            Sign up
          </button>
        </form>
      </div>
      <div className='ft'>
        <Footer />
      </div>
    </>
  );
};
export default FormSignup;