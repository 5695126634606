import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
function Home() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero"
        heroImg="https://images.unsplash.com/photo-1546410531-bb4caa6b424d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzl8fHN0dWRlbnRzfGVufDB8fDB8fHww&auto=format&fit=crop&w=1000&q=60"
        title="Welcome To QFinder"
        text="For Students, Teacher and Institutes
      Complete Online Teaching Solution for Institutes, Teachers and Students.
      Download Previous Year Question Papers and Mock Tests."
        btntext=
        <a style={{ textDecoration: "none", color: "black" }}>
          Start Here
        </a>
        url="/service"
        btnClass="show"
        />
      <Footer />
    </>
  );
}

export default Home;
