export const MenuItems = [
  {
    title: "Home",
    url: "/",
    cName: "nav-links",
    icon: "fa-sharp fa-solid fa-house fa-bounce"
  },
  {
    title: "About",
    url: "/about",
    cName: "nav-links",
    icon: "fa-solid fa-circle-info"
  },
  {
    title: "Contact",
    url: "/contact",
    cName: "nav-links",
    icon: "fa-solid fa-headphones"
  },
  {
    title: "Sign UP",
    url: "/Signup",
    cName: "nav-links"
  },
  {
    title: "Sign In",
    url: "/login",
    cName: "nav-links"
  }
];
