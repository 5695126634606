import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import AboutImg from "../assets/img3.jpg";
import Footer from "../components/Footer";

function About() {
  const mystyle = {
    color: "black",
    fontsize: "3rem"
  };
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={AboutImg}
        title="Journey Of QFinder"
        btnClass="hide"
      />
      {""}
      <br />
      <h1 style={{ mystyle }}>About Us</h1>
      {""}
      <br />
      <p style={{ fontSize: "22px" }}>
        Helping Students & Teachers Since 1995 We provide complete online
        teaching solution for Individual teacher or Institute. <br /> You can
        easily start your own Online Coaching Website. We will provide you the
        complete training and and material required to teach online.
      </p>{" "}
      <br />
      <p style={{ fontSize: "22px" }}>
        We always update our technology so that your institute can score 100% in
        business.
        <br /> Institute offering any courses like SSC, Railways, Medical, PMT,
        JEE or even Engineering courses, we have complete list of features that
        will help you to achive your goals easily.
        {""}
        <br /> <br /> The biggest online platform for educational institutes in
        India.
      </p>
      {""}
      <br />
      <Footer />
    </>
  );
}

export default About;
