import React, { useState } from 'react';
import './login.css';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import im from "../assets/retrosupply-jLwVAUtLOAQ-unsplash.jpg";
import {useNavigate} from "react-router-dom";
function SignInForm() {
  let navigate = useNavigate();
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});   

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
  
    if (validationErrors) {
      const { email, password } = state;
  
      try {
        const res = await fetch('https://qfinder-8cfa2-default-rtdb.firebaseio.com/userDataRecords.json', {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
  
        const data = await res.json();
  
        const userFound = Object.values(data).some((userData) => {
          return userData.email === email && userData.password === password;
        });
  
        if (userFound) {
          console.log("Login successful!");
          navigate('/')
        } else {
          console.log("Login unsuccessful!");
          setErrors({ login: "Incorrect email or password" });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };
  
  
  const validateForm = () => {
    const errors = {};

    if (!state.email.trim()) {
      errors.email = "Email is required";
    } else if (!isValidEmail(state.email)) {
      errors.email = "Invalid email address";
    }

    if (!state.password.trim()) {
      errors.password = "Password is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  return (
    <>
    <img src={im} className='inm'></img>
      <div className="sign-in-container">
        <div style={{ position: "absolute" }}>
          <Navbar />
        </div>
        <form onSubmit={handleOnSubmit} method='POST' className='fo'>
          <div className="social-container">
            <h1 style={{ color: "white" }}>Sign in</h1>
          </div>
          <br />
          <input
            placeholder="Email"
            name="email"
            value={state.email}
            onChange={handleChange}
          />
          {errors.email && <p className="error">{errors.email}</p>}
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={state.password}
            onChange={handleChange}
          />
          {errors.password && <p className="error">{errors.password}</p>}
          <br />
          <button type="submit">Sign In</button>
        </form>
      </div>
      <div className="ft3">
        <Footer />
      </div>
    </>
  );
}

export default SignInForm;
