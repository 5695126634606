import "./FooterStyles.css";
const Footer = () => {
  return (
    <div className="footer">
      <div className="top">
        <div>
          <h1>QFinder</h1>
          <p>Question Paper Generator</p>
        </div>
        <a href="https://www.facebook.com/gokul.priyan.3760" target="blank">
          <i class="fa-brands fa-facebook"></i>
        </a>
        <a href="https://www.instagram.com/qfinder_gps/" target="blank">
          <i class="fa-brands fa-instagram"></i>
        </a>
        <a href="https://discord.com/channels/@me" target="blank">
          <i class="fa-brands fa-discord"></i>
        </a>
        <a href="https://twitter.com/gokulpriyan781" target="blank">
          <i class="fa-brands fa-twitter"></i>
        </a>
      </div>
      <div className="bottom">
        <div>
          <h4>Project</h4>
          <a href="/">Live Test</a>
          <a href="https://quizizz.com/join" target="blank">
            Quizz
          </a>
          <a href="https://www.youtube.com/@JennyslecturesCSIT" target="blank">
            Online Videos
          </a>
          <a
            href="https://calendar.google.com/calendar/u/0/r?pli=1"
            target="blank"
          >
            Exam Calender
          </a>
        </div>
        <div>
          <h4>Community</h4>
          <a href="https://github.com/">Github</a>
          <a href="Contact">Issues</a>
          <a href="/">Project</a>
          <a href="https://twitter.com/gokulpriyan781" target="blank">
            Twitter
          </a>
        </div>
        <div>
          <h4>Help</h4>
          <a href="/">Support</a>
          <a href="/">Troubleshooting</a>
          <a href="/">Contact Us</a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
