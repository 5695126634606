export const data = [
    {
        "id": 1,
        "name": "NEET",
        "url": "https://drive.google.com/drive/folders/11MhsaSWPtkv8jyDS5pgpKFeCdKtVes6W?usp=drive_link",
        "discord":""
      },
      {
        "id": 2,
        "name": "JEE ADVANCED",
        "url": "https://drive.google.com/drive/folders/1F4ez9Rew655nsOUx14IA85ckt94-iE-t?usp=sharing"
      },
      {
        "id": 3,
        "name": "JEE MAINS",
        "url": "https://drive.google.com/drive/folders/1WJ207IR3d7ftoW7DcGo3Q86EdTuKB6lC?usp=sharing"
      },
      {
        "id": 4,
        "name": "GATE",
        "url": "https://drive.google.com/drive/folders/1bMtPKpGHhplU_ZuIVzHzSnbVaWVVB-2C?usp=sharing"
      },
      {
        "id": 5,
        "name": "CAT",
        "url": "https://drive.google.com/drive/folders/1CISiP_nIyB_4oaIskqptd0jNIZxKTzz7?usp=sharing"
      }
  ];